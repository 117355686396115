import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useAuth} from '../../../../../app/modules/auth'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useTaskContext} from '../../../../../app/modules/tasks'
import {useTemplateContext} from '../../../../../app/modules/templates'
import {getNextActions} from '../../../../../app/modules/templates/components/Functions'
import {Task} from '../../../../../app/pages/maintenance/components/TaskInterface'
import './SidebarMenu.css'

const SidebarMenuMain = () => {
  const {t} = useTranslation(['navigation'], {keyPrefix: 'sidebar'})
  const {isUserAllowed, isMaintenanceAllowed} = useAuth()
  const {tasks} = useTaskContext()
  const {templates} = useTemplateContext()
  const [pendingTasks, setPendingTasks] = useState(0)

  useEffect(() => {
    setPendingTasks(
      tasks.filter((task: Task) => {
        if (task.ended_at) return false

        const currentTemplate = templates.find((t) => t.id === task.id_template)
        if (!currentTemplate) return false

        return (
          isUserAllowed('maintenance', 'edit') ||
          isMaintenanceAllowed(task.id_template, getNextActions(currentTemplate, task)[0])
        )
      }).length ?? 0
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, templates])

  return (
    <>
      <SidebarMenuItem to='/home' icon='home' title={t('home')} />
      {isUserAllowed('machines') && (
        <SidebarMenuItem to='/machines' icon='technology' title={t('machines')} />
      )}

      {isUserAllowed('maintenance') && (
        <SidebarMenuItemWithSub
          to='/maintenance'
          icon='office-bag'
          title={t('maintenance.title')}
          active={true}
        >
          {(isUserAllowed('maintenance', 'edit') || isMaintenanceAllowed(null)) && (
            <SidebarMenuItem to='/maintenance/list' title={t('maintenance.list')} hasBullet={true}>
              {pendingTasks > 0 ? (
                <span
                  className='position-absolute badge badge-warning'
                  style={{right: '20px', top: '10px'}}
                >
                  {pendingTasks}
                </span>
              ) : null}
            </SidebarMenuItem>
          )}

          <SidebarMenuItem
            to='/maintenance/history'
            title={t('maintenance.history')}
            hasBullet={true}
          />

          {(isUserAllowed('maintenance', 'edit') || isMaintenanceAllowed(null, 'create')) && (
            <SidebarMenuItem
              to='/maintenance/schedule'
              title={t('maintenance.schedule')}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {isUserAllowed('teams') && <SidebarMenuItem to='/teams' icon='courier' title={t('teams')} />}
      {isUserAllowed('users') && <SidebarMenuItem to='/users' icon='people' title={t('users')} />}
      {isUserAllowed('templates') && (
        <SidebarMenuItem to='/template' icon='note-2' title={t('templates')} />
      )}
    </>
  )
}

export {SidebarMenuMain}
