import {createContext, useContext, useEffect, useState} from 'react'
import axios from 'axios'
import Event from '../../GenericComponents/EventCalendar/EventInterface'
import {Task, Activity} from '../../pages/maintenance/components/TaskInterface'
import {sortTasks} from './components/Functions'
import {getNextActions} from '../templates/components/Functions'
import {useMachineContext} from '../machines'
import {useTemplateContext} from '../templates'
import {useAuth} from '../auth'
import {socket} from '../socket'

interface TaskContextType {
  tasks: Task[]
  getHistory: (start: Date, end: Date) => void
  addTask: (e: Event) => Promise<void>
  updateTask: (e: Event) => Promise<void>
  setTaskAction: (task: Task, activity: Activity) => Promise<void>
  removeTask: (id: number) => Promise<void>
}

const TaskContext = createContext<TaskContextType | undefined>(undefined)

export const useTaskContext = () => {
  const context = useContext(TaskContext)
  if (!context) {
    throw new Error('useTaskContext must be used within a TaskProvider')
  }
  return context
}

export function TaskContextProvider({children}) {
  const [dates, setDates] = useState<[Date | null, Date | null]>([null, null])
  const [tasks, setTasks] = useState<Task[]>([])
  const {machines, setMachines} = useMachineContext()
  const {templates} = useTemplateContext()
  const {currentUser} = useAuth()

  useEffect(() => {
    fetchList()
    fetchPending()

    // Handle socket events
    socket.connect()

    socket.on('connect', () => {
      console.debug('taskContext connected to server', socket.id)
    })

    socket.on('disconnect', () => {
      console.debug('taskContext disconnected from server')
    })

    socket.on('task-removed', (removedTask) => {
      setTasks((prevTasks) => prevTasks.filter((m) => m.id !== removedTask.id))
    })

    socket.on('task-updated', (updatedTask) => {
      setTasks((prevTasks) =>
        prevTasks.map((m) =>
          m.id === updatedTask.id ? Object.assign(m, updatedTask, {updated_at: new Date()}) : m
        )
      )
    })

    socket.on('task-inserted', (insertedTask) => {
      setTasks((prevTasks) => [...prevTasks, insertedTask])
    })

    socket.on('task-activity', (taskActivity) => {
      setTasks((prevTasks) =>
        prevTasks.map((m) => {
          if (m.id === taskActivity.id_task) {
            // Adiciona nova atividade
            m.activity.push(Object.assign(taskActivity, {created_at: new Date()}))

            // Verifica fim de tarefa e atualiza data de fim
            if (hasTaskEnded(m)) {
              m.ended_at = new Date()
              m.ended_by = taskActivity.created_by
            }
          }
          return m
        })
      )
    })

    return () => {
      socket.off('task-removed')
      socket.off('task-updated')
      socket.off('task-inserted')
      socket.off('task-activity')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getHistory = (start: Date, end: Date) => {
    const [prevStart, prevEnd] = dates

    // Primeiro pedido de histórico
    if (!prevStart || !prevEnd) {
      fetchHistory(start, end)
      setDates([start, end])
      return
    }

    // Verifica necessidade de pedir dados
    if (start < prevStart) {
      fetchHistory(start, prevStart)
    }

    if (end > prevEnd) {
      fetchHistory(prevEnd, end)
    }

    setDates([start < prevStart ? start : prevStart, end > prevEnd ? end : prevEnd])
  }

  const fetchList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/task/list`)
      .then((response) => {
        setTasks(response.data)
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const fetchHistory = (start: Date, end: Date) => {
    console.debug('fetching history', start, end)
    const url = `${process.env.REACT_APP_API_URL}/api/task/list?start=${start.toISOString()}&end=${end.toISOString()}`

    axios
      .get(url)
      .then((response) => {
        setTasks((prevTasks) => {
          const arr = prevTasks.map((t) => {
            response.data.forEach((task: Task) => {
              if (t.id === task.id) return Object.assign(t, task)
            })
            return t
          })

          response.data.forEach((task: Task) => {
            if (!prevTasks.find((t) => t.id === task.id)) {
              arr.push(task)
            }
          })

          return arr
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const fetchPending = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/task/pending`)
      .then((response) => {
        setTasks(response.data)
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const removeTask = async (id: number) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/task/${id}`)
      .then((response) => {
        console.log(response)

        setTasks((prevTasks) => prevTasks.filter((m) => m.id !== id))
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const updateTask = async (e: Event) => {
    const task = {
      ...e,
      id: Number(e.id),
      name: e.title,
      scheduled_for: e.start,
      scheduled_end: e.end,
    } as unknown as Task

    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/task/${task.id}`, task)
      .then((response) => {
        console.log(response)

        setTasks((prevTasks) =>
          prevTasks.map((t) => (t.id === task.id ? Object.assign(t, task) : t))
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const addTask = async (e: Event) => {
    let task = {
      ...e,
      name: e.title,
      scheduled_for: e.start,
      scheduled_end: e.end,
      created_at: new Date(),
      created_by: currentUser?.id,
      activity: [],
    } as unknown as Task

    return axios
      .put(`${process.env.REACT_APP_API_URL}/api/task`, task)
      .then((response) => {
        console.log(response)

        const {id_task} = response.data
        setTasks((prevTasks) => {
          task.id = id_task
          return [...prevTasks, task]
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const setTaskAction = async (task: Task, activity: Activity) => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/task/${task.id}/${activity.type}`, activity)
      .then((response) => {
        if (currentUser && response.data.id) {
          const now = new Date()

          task.activity.push({
            id: response.data.id,
            type: activity.type,
            text: activity.text,
            created_at: now,
            created_by: currentUser.id,
          })

          // Verifica fim de tarefa e atualiza data de fim
          if (hasTaskEnded(task)) {
            task.ended_at = now
            task.ended_by = currentUser.id
          }
        }

        setTasks((prevTasks) => prevTasks.map((t) => (t.id === task.id ? task : t)))
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const setMachineTasks = () => {
    setMachines((prevMachines) => {
      return prevMachines.map((m) => {
        const machineTasks = tasks.filter((t: Task) => t.id_machine === m.id),
          pending = machineTasks.filter((t: Task) => {
            if (!t.activity) return false
            const list = t.activity.slice().reverse()
            const start = list.find((a) => a.type === 'start') ?? null,
              end = list.find((a) => a.type === 'end') ?? null
            return (start && !end) || (start && end && start.created_at > end.created_at)
          }),
          next = machineTasks.filter(
            (t: Task) => !t.activity || !t.activity.find((a) => a.type === 'start')
          )

        // Se não houver tarefas pendentes
        m.operational = !pending.length

        // Tarefa atual
        m.current_task = pending.sort(sortTasks)[0]

        // Próxima tarefa
        m.next_task = next.sort(sortTasks)[0]

        return m
      })
    })
  }

  // Verificação de tarefa concluída
  const hasTaskEnded = (task: Task) => {
    const template = templates.find((t) => t.id === task.id_template)
    if (!template) return false

    const nextActions = getNextActions(template, task)
    if (!nextActions.length) return true

    return false
  }

  useEffect(() => {
    if (machines.length) setMachineTasks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, machines.length])

  return (
    <TaskContext.Provider
      value={{tasks, getHistory, addTask, updateTask, setTaskAction, removeTask}}
    >
      {children}
    </TaskContext.Provider>
  )
}
