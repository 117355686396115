import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {getBackground, formatLocaleDate} from '../../../helpers'
import {useMachineContext} from '../../../modules/machines'
import {EditButton} from './MachineButtons'
import MachineInterface from './MachineInterface'

interface Prop {
  selectMachine: (machine: MachineInterface | null) => void
}

const MachineList = ({selectMachine}: Prop) => {
  const {t} = useTranslation(['machine'], {keyPrefix: 'list'})
  const {machines} = useMachineContext()

  const list = useMemo(
    () =>
      machines.map((machine: MachineInterface, index: number) => {
        const current = {
            date: machine.current_task?.activity.find((a) => a.type === 'start')?.created_at,
            name: machine.current_task?.name,
          },
          next = machine.next_task

        return (
          <tr key={index} className={getBackground(machine.operational ? 0 : 1, 'light')}>
            <td>{machine.name}</td>
            <td>{current.date && `${current.name}, ${formatLocaleDate(current.date)}`}</td>
            <td>
              {next?.scheduled_for && `${next.name}, ${formatLocaleDate(next.scheduled_for)}`}
            </td>
            <td>
              <EditButton selectMachine={() => selectMachine(machine)} size='sm' />
            </td>
          </tr>
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [machines]
  )

  return (
    <>
      <div className='scroll g-5 mt-10 mb-5'>
        <table className='table table-rounded table-row-bordered bg-body border gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
              <th>{t('name')}</th>
              <th>{t('current')}</th>
              <th>{t('next')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{list}</tbody>
        </table>
      </div>
    </>
  )
}
export default MachineList
